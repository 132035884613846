import { Link } from "preact-router";

const Sidebar = (props) => {
  return (
    <aside class="sidebar is-open">
      <div class="sidebar__head">
        <Link href="#" onClick={() => props.toggleSidebar()}>
          <svg class="icon icon-close icon--small">
            <use xlinkHref="#icon-close"></use>
          </svg>
        </Link>
        <img
          src={
            (props.homepage &&
              "https://legendeholzdorf-backend.einshorn.de" +
                props.homepage.headerImage.url) ||
            "/assets/img/photo-3.jpg"
          }
          alt=""
        />
        <Link href="/" onClick={() => props.toggleSidebar()}>
          <svg class="icon icon-logo">
            <use xlinkHref="#icon-logo"></use>
          </svg>
        </Link>
      </div>
      <nav class="tour-nav">
        <ul>
          <li>
            <Link href="/" onClick={() => props.toggleSidebar()}>
              Audiotouren
            </Link>
            <ul>
              {props.tours &&
                props.tours.map((t) => (
                  <li>
                    <Link
                      href={"/tour/" + t.id}
                      onClick={() => props.toggleSidebar()}
                    >
                      {t.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <Link href={"/map"} onClick={() => props.toggleSidebar()}>
              Umgebungskarte
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            <a href="https://legende-holzdorf.de/">Veranstaltungen</a>
          </li>
          <li>
            <Link href={"/contributors"} onClick={() => props.toggleSidebar()}>
              Mitwirkende
            </Link>
          </li>
          {false && (
            <li>
              <a href="#0">Hilfe</a>
            </li>
          )}
        </ul>
      </nav>
      <nav class="sub-nav"></nav>
      <nav class="legal-nav">
        <ul>
          <li>
            <Link href={"/imprint"} onClick={() => props.toggleSidebar()}>
              Impressum
            </Link>
          </li>
          <li>
            <Link href={"/privacy"} onClick={() => props.toggleSidebar()}>
              Datenschutz
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};
export default Sidebar;
