const Body = (props) => {
  var classy = "";

  classy = props.sidebar ? classy + " sidebar-open " : classy;
  classy = props.overlay ? classy + " overlay-open " : classy;
  classy = props.player
    ? props.miniPlayer
      ? classy + "mini-player-active"
      : classy + " player-active "
    : classy;

  //sidebar-open und player-active und overlay-open
  return <mybody class={classy}>{props.children}</mybody>;
};
export default Body;
