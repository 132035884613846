import "./style";
import App from "./components/app";

import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
  urlBase: "https://legendeholzdorf-matomo.einshorn.de",
  siteId: 1,
  //linkTracking: false, // Important!
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
  },
});

const AppWithMatomo = (props) => {
  return (
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
  );
};

export default AppWithMatomo;
