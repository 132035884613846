import React from "react";
import ReactMarkdown from "react-markdown";

export const OverlayGPSInfo = (props) => {
  return (
    <div class="overlay">
      <div class="overlay__content">
        <h1>{(props.intro && props.intro.name) || ""}</h1>
        {!props.intro && (
          <>
            <div class="lds-ring">
              <div></div>
              <div></div>
            </div>
            <p style={{ "text-align": "center" }}>Audioguide wird geladen...</p>
          </>
        )}
        {props.intro && (
          <ReactMarkdown children={props.intro.description}></ReactMarkdown>
        )}
        {props.intro && (
          <section
            class="station-buttons"
            style="padding: 0 ; margin-bottom: 0px; background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%; margin-left: -10px; margin-right: -10px;"
          >
            <button
              class="button button-square"
              onClick={(e) => props.toggleOverlayGPSInfoButton(true)}
            >
              {(props.intro && props.intro.buttontextgps) || "Los geht's"}
            </button>
            <button
              class="button button-square button-square--blue"
              onClick={(e) => props.toggleOverlayGPSInfoButton(false)}
            >
              {(props.intro && props.intro.buttontextskip) || "Überspringen"}
            </button>
          </section>
        )}
      </div>
    </div>
  );
};
